import React, { useState } from "react";
import { useEffect } from "react";
import { useActions } from "../../redux/actions";
import { WorkHistoryHeaders} from './constants';
import * as ProfileActions from "../../redux/actions/provider";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import { RootState } from "../../model";
import { connect } from "react-redux";

interface WorkHistoryModalProps {
    UserID: any
    DocID: any
    DocData: any
    openState: boolean,
    onClose: any,
    user: any
}
function WorkHistoryModal(props: WorkHistoryModalProps) {
    const { openState, onClose, UserID } = props;
    const [open, setOpen] = React.useState(openState);
    const providerActions = useActions(ProfileActions);
    const initialState = {
        workHistory: []
    }
    const [state, setState] = useState(initialState);
    useEffect(() => {
        loadData();
       
    }, [UserID])

    const loadData = async () => {
        const { UserID } = props;
        const results: any = await providerActions.getDoctorWorkHistory(UserID);
        setState({ ...state, workHistory: results });
        setOpen(true);
    }

    const sendEmail = async () => {
        setOpen(false);

        const { UserID } = props;
        await providerActions.sendEmailToLoggedInUser(props.user.id, UserID);
    }

    const { DocData } = props
    const { workHistory } = state
    return (
        <Modal
            onClose={ onClose }
            open={open}
            trigger={<Button primary fluid style={{ marginTop: "18px" }} onClick={() => { }}
            >Work History</Button>}
        >
            <Modal.Header>Dr {DocData.firstName || ''}  {DocData.lastName || ''}  &nbsp; &nbsp; <span >  Status:</span> {DocData.status ? 'Active' : 'Not Active'} &nbsp; &nbsp; <span> Home Location:</span> {DocData.homeLocation || ''}</Modal.Header>
            <Modal.Content>
                <div className="ui card">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                            <Table.Header>
                                <Table.Row>
                                    {
                                        WorkHistoryHeaders.map((header, key) =>
                                            <Table.HeaderCell
                                                key={key}
                                                style={{ color: "#d16f26" }}
                                                className={`cursorPointer`}
                                                onClick={() => { }}
                                            >
                                                <div>{header.label}
                                                    <Icon className={'sort'} /></div>
                                            </Table.HeaderCell>)
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    workHistory.length > 0 && workHistory.map((data: any, key: number) => {
                                        return (
                                            <Table.Row key={key}>
                                                {
                                                    WorkHistoryHeaders.map((field, i) => <Table.Cell key={i}>
                                                        {(field.format) ? field.format(data[field.value]) : data[field.value]}
                                                    </Table.Cell>)
                                                }
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    primary
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => setOpen(false)}
                    positive
                />
                <Button
                    content="Send Email"
                    primary
                    labelPosition='right'
                    icon='envelope'
                    onClick={() => sendEmail()}
                    positive
                    color={'green'}
                />
            </Modal.Actions>
        </Modal>
    )
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(WorkHistoryModal)