import React, {useEffect, useState} from "react";
import {RootState} from "../model";
import {connect} from "react-redux";
import Template from "./Template";
import {useActions} from "../redux/actions";
import * as reportsActions from "../redux/actions/report";
import {Table} from "semantic-ui-react";
import moment from "moment";
import {getUsageOdFIleUpload} from "../redux/actions/report";


interface Props {

}

function UsageReportOdFile(props: Props) {
    const reportsAction = useActions(reportsActions)
    const [state, setState] = useState({
        report: []
    });
    useEffect(() => {
        loadReport();
    }, []);


    const loadReport = () => {
        reportsAction.getUsageOdFIleUpload().then((report: any) => {
            setState({...state, report: report})
        });
    }

    return (
        <Template activeLink="usage-report">
            <div className="ui card">
                <div className="content">
                    <h2 className="float-left mr10">Usage Report</h2>
                </div>
            </div>
            <div className={"ui card"}>
                <Table celled={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            <Table.HeaderCell>DateTime</Table.HeaderCell>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                            <Table.HeaderCell>Result</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {state.report && state.report.map((item: any) => {
                            return (
                                <Table.Row key={item.id}>
                                    <Table.Cell>{item.user.firstname + ' ' + item.user.lastname}</Table.Cell>
                                    <Table.Cell>{item.action}</Table.Cell>
                                    <Table.Cell>{moment(item.createdAt).format('MM-DD-YYYY hh:mm:ss A')}</Table.Cell>
                                    <Table.Cell>{JSON.stringify(item.data)}</Table.Cell>
                                    <Table.Cell>{item.result}</Table.Cell>
                                </Table.Row>
                            )
                        })
                        }
                    </Table.Body>
                </Table>
            </div>
        </Template>
    );
}


function mapStateToProps(state: RootState) {
    return {};
}

export default connect(mapStateToProps)(UsageReportOdFile);

