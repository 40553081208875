import React, {useEffect, useState} from "react";
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";
import EditWebsites from "../../../../components/EditWebsites";
import {useActions} from "../../../../redux/actions";
import * as practiceActions from "../../../../redux/actions/practice";
function EditSiteModal(props: any) {
    const [state, setState] = useState({
        loading: false,
        options: [],
        practices: [],
    })
    const practiceAction = useActions(practiceActions)
    const setPractices = (res: any) => {
        let practiceList: any = [{key: 0, text: 'CORP', value: 'CORP'}];

        setState({...state, loading: false})
        setState({...state, practices: res})

        if (res && res.length) {
            res.forEach((item: any) => {
                practiceList.push({
                    key: item.id,
                    value: item.practice,
                    text: item.practice
                })
            })
            setState({...state, options: practiceList})
        }
    }

    useEffect(() => {
        setState({...state, loading: true})
        practiceAction.fetchWebsitesPractices(setPractices, '')
    }, [])
    

    return (
        <Modal className='modalCenter' size="large" onClose={() => props.showEditSiteModalHandler(true)} open={true}>
            {
                state.loading &&
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            }
            <Modal.Header>
                <b>WEBSITE</b> <a className="ui green label">EDIT</a>
                <div className="ui top right attached label production_right_label">
                    <Button onClick={() => props.showEditSiteModalHandler(true)} inverted>
                        Close
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Content>
                <EditWebsites
                    practices={state.options}
                    initialValue={{
                        id: props.data.id,
                        practice: Array.isArray(props.data.practice) ? props.data.practice : props.data.practice.split(','),
                        type: props.data.type,
                        liveSite: props.data.liveSite,
                        betaSite: props.data.betaSite,
                        siteManager: props.data.siteManager,
                        contentManager: props.data.contentManager,
                        liveHosting: props.data.liveHosting,
                        betaHosting: props.data.betaHosting,
                        liveIpAddress: props.data.liveIpAddress,
                        betaIpAddress: props.data.betaIpAddress,
                        wordFenceInstalled: props.data.wordFenceInstalled,
                        roadMap: props.data.roadMap,
                    }}
                    onCancel={() => props.showEditSiteModalHandler(true)}
                    onSaveData={props.onSubmitHandler}
                />
            </Modal.Content>
        </Modal>
    )
}
export default EditSiteModal