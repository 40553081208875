import _ from 'lodash';
import * as React from 'react';
import { Button, Label, Modal, Table } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid';

export interface PMDScheduleTableProps {
  practicename: string,
  schedulePMDDataOptions: [],
  schedulesShowPopup: boolean
}

export interface PMDScheduleTableState {
  practicename: string,
  schedulePMDDataOptions: [],
  schedulesShowPopup: boolean
}

class PMDScheduleTable extends React.Component<PMDScheduleTableProps, PMDScheduleTableState> {
  constructor(props: PMDScheduleTableProps) {
    super(props);
    this.state = {
      practicename: props.practicename,
      schedulePMDDataOptions: props.schedulePMDDataOptions,
      schedulesShowPopup: props.schedulesShowPopup
    }
    this.setPracticeName = this.setPracticeName.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename: value
    })
  }

  componentWillReceiveProps(nextProps: PMDScheduleTableProps) {
    this.setState({
      practicename: nextProps.practicename,
      schedulePMDDataOptions: nextProps.schedulePMDDataOptions,
      schedulesShowPopup: nextProps.schedulesShowPopup
    })
  }


  setOpen(value: boolean) {
    this.setState({
      schedulesShowPopup: value
    })
  }

  getDistinct(scheduleitems: any[]) {
    const notDeleted = scheduleitems.filter(item => !item.is_deleted);

    return notDeleted.flatMap(item => {
      const doctorNames = item.userName.split(';').filter((name: any) => name.trim());
      return doctorNames.map((doctorName: any) => ({
        ...item,
        displayName: doctorName.trim()
      }));
    });
  }

  render() {
    const isTodayOrTomorrow = (startDate: number) => {
      const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const start = new Date(new Date(startDate).toLocaleString('en-US', { timeZone: 'America/Chicago' }));

      if (start.toDateString() === today.toDateString()) {
        return "Today";
      } else if (start.toDateString() === tomorrow.toDateString()) {
        return "Tomorrow";
      }
      return "";
    };

    const formatTimeFromMillis = (timeInMillis: number) => {
      const totalMinutes = timeInMillis / (1000 * 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);

      const date = new Date();
      date.setHours(hours, minutes, 0, 0);

      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    };

    const sortSchedules = (schedules: any[]) => {
      const chicagoToday = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
      const chicagoTomorrow = new Date(chicagoToday);
      chicagoTomorrow.setDate(chicagoToday.getDate() + 1);

      return _.sortBy(schedules, [
        // First sort by date (Today before Tomorrow)
        (item) => {
          const startInChicago = new Date(new Date(item.startDate).toLocaleString('en-US', { timeZone: 'America/Chicago' }));
          
          if (startInChicago.toDateString() === chicagoToday.toDateString()) return 1;
          if (startInChicago.toDateString() === chicagoTomorrow.toDateString()) return 2;
          return 3;
        },
        // Then sort by start time
        'start'
      ]);
    };

    // Check if any record has time data
    const hasTimeData = this.props.schedulePMDDataOptions?.some(
      (item: any) => item.start !== undefined && item.end !== undefined
    );

    return (
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.schedulesShowPopup}
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
        trigger={<div className="ui labeled button">
          <div className="ui basic blue button">
            <u className="pmd_numbers_clickable">Schedules</u>
          </div>
          <a className="ui basic white left pointing label lightorangebg">
            {this.getDistinct(this.props.schedulePMDDataOptions).length}
          </a>
        </div>
        }
      >
        <Modal.Header>Doctor Schedules
          <Label color={this.props.schedulePMDDataOptions.length > 0 ? 'green' : 'red'} floating>
            {this.getDistinct(this.props.schedulePMDDataOptions).length}
          </Label>
          <div className="ui top right attached label production_right_label">
            <Button onClick={() => this.setOpen(false)} inverted>
              Close
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content >

          <Table className="table table-striped table-hover my-0 TableHeaderFixed">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell><div className="tbl-header">Scheduled</div></Table.HeaderCell>
                {hasTimeData && (
                  <Table.HeaderCell><div className="tbl-header">Time</div></Table.HeaderCell>
                )}
                <Table.HeaderCell><div className="tbl-header">Dr Name</div></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sortSchedules(this.getDistinct(this.props.schedulePMDDataOptions)).map(
                ({ role, displayName, startDate, endDate, start, end }) => {
                  return (
                    <Table.Row key={uuidv4()}>
                      <Table.Cell>{isTodayOrTomorrow(startDate)}</Table.Cell>
                      {hasTimeData && (
                        <Table.Cell>
                          {start && end ? `${formatTimeFromMillis(start)} - ${formatTimeFromMillis(end)}` : ''}
                        </Table.Cell>
                      )}
                      <Table.Cell>{role} {displayName}</Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
        </Modal.Actions>
      </Modal>


    )
  }
}

export default PMDScheduleTable;