import React from 'react';
import {Accordion, Icon, List, Menu, Sidebar} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {GrandSubMenu, MainMenu, SubMenu} from '../constants';
import Logo from '../styles/images/logoMain.png';
import {Account} from "../model/user";
import {useActions} from '../redux/actions';
import * as appActions from '../redux/actions/app';
import {menusArray, menuInsuranceFileUpload} from '../menus';
import {menuOSP} from '../menuOSP';
import {menuptvArray} from '../menuptv';
import {menuAdminArray} from "../menu-admin";
import {menuSPOTArray} from "../menuSPOT";
import {v4 as uuidv4} from 'uuid';

interface Props {
    visible: boolean,
    user: Account,
    savePartnerUsage: Function
}

export default function SideBarMain(props:Props) {
    const parseLeftMenuPath = (menu:string) => `/${menu.toLowerCase().split(' ').join('-')}`;
    const appAction = useActions(appActions);
    const {user, visible, savePartnerUsage} = props;
    let basePath = window.location.pathname.split('/')[1];
    basePath = basePath.indexOf('-') > -1
        ? basePath.split('-').join(' ')
        : basePath;
    const basePathIndex = basePath
        ? menusArray.findIndex(i => (i.name || i.title).toLowerCase() === basePath.toLowerCase())
        : -1;
    const savePartners = (partnerName: string) => {
        savePartnerUsage(partnerName)
    };
    const closeSideBar = () => {
        appAction.SidebarOpen(false)
    }

    const defaultPanelsList = (menu: any) => {
        return menu.map((menu: MainMenu, i: any) => {

            // Check if role is assign for menu
            if (menu.role && menu.role.length) {
                let roleExist = false;
                menu.role.forEach((role: string) => {
                    if (user.role.indexOf(role.toLowerCase()) >= 0) {
                        roleExist = true;
                    }
                });
                if (!roleExist) {
                    return {key: `panel-${i}`};
                }
            }

            const parentLink = `${parseLeftMenuPath(menu.name)}`;

            // Child Menu
            if (menu.subMenus && menu.subMenus.length > 0) {
                const childMenus = menu.subMenus.map((subMenu: SubMenu, j: number) => {

                    // Dont show in menu bar if menu hide is set
                    if (subMenu.hide) {
                        return true;
                    }

                    // Check if role is assign for menu
                    if (subMenu.role && subMenu.role.length) {
                        let roleExist = false;
                        subMenu.role.forEach((role: string) => {
                            if(user.role.indexOf(role.toLowerCase()) >= 0) {
                                roleExist = true;
                            }
                        });
                        if (!roleExist) {
                            return false;
                        }
                    }
                    let childPath = window.location.pathname.split('/')[2];
                    childPath = childPath && childPath.indexOf('-') > -1
                        ? childPath.split('-').join(' ')
                        : childPath;
                    const childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;

                    return (
                        <List.Item
                            key={uuidv4()}
                            className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                        >
                            <Link
                                to={childLink}
                                onClick={closeSideBar}
                                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                            >
                                {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}/> :
                                    <Icon name={subMenu.icon}/>}
                                {subMenu.title}
                            </Link>
                        </List.Item>
                    );
                });
                return {
                    key: `panel-${i}`,
                    title: {
                        content: (<span  onClick={() =>savePartners(menu.partner)} className={(basePath === menu.name.toLowerCase()) ? 'parentActive' : ''}>
        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
            <Icon name={menu.icon}/>}
                            {menu.title}
                            <Icon
                                name={(i === basePathIndex) ? 'angle up' : 'angle down'}
                            />
      </span>)
                    },
                    content: {
                        content: (<List  >{childMenus}</List>)
                    }
                };
            }

            // Don't show in menu bar if menu hide is set
            if (menu.hide) {
                return {key: `panel-${i}`};
            }
            return {
                key: `panel-${i}`,
                title: {
                    content: <Link
                        onClick={() =>{
                            savePartners(menu.partner);
                            closeSideBar();
                        }}
                        to={parentLink}
                    >
                        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
                            <Icon name={menu.icon}/>}
                        {menu.title}
                    </Link>
                }
            };
        });
    }

    let panels:any;
    if (basePathIndex > -1) {
        panels = defaultPanelsList(menusArray)
    }
    if (basePath === 'open dental insurance'){
        panels = defaultPanelsList(menuInsuranceFileUpload)
    }

    if (basePath === 'open surgery pcr report' || basePath === 'open surgery dashboard'
        || basePath === 'referral data os practices' || basePath === 'referral data future os offices' ) {
        panels = menuOSP.map((menu: MainMenu, i) => {

            // Check if role is assign for menu
            if (menu.role && menu.role.length) {
                let roleExist = false;
                menu.role.forEach((role: string) => {
                    if (user.role.indexOf(role.toLowerCase()) >= 0) {
                        roleExist = true;
                    }
                });
                if (!roleExist) {
                    return {key: `panel-${i}`};
                }
            }

            const parentLink = `${parseLeftMenuPath(menu.name)}`;
            // Child Menu
            if (menu.subMenus && menu.subMenus.length > 0) {
                const childMenus = menu.subMenus.map((subMenu: SubMenu, j: number) => {

                    // Dont show in menu bar if menu hide is set
                    if (subMenu.hide) {
                        return true;
                    }

                    // Check if role is assign for menu
                    if (subMenu.role && subMenu.role.length) {
                        let roleExist = false;
                        subMenu.role.forEach((role: string) => {
                            if(user.role.indexOf(role.toLowerCase()) >= 0) {
                                roleExist = true;
                            }
                        });
                        if (!roleExist) {
                            return false;
                        }
                    }
                    let childPath = window.location.pathname.split('/')[2];
                    childPath = childPath && childPath.indexOf('-') > -1
                        ? childPath.split('-').join(' ')
                        : childPath;
                    const childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;
                    return (
                        <List.Item
                            key={uuidv4()}
                            className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                        >
                            <Link
                                to={childLink}
                                onClick={closeSideBar}
                                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                            >
                                {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}/> :
                                    <Icon name={subMenu.icon}/>}
                                {subMenu.title}
                            </Link>
                        </List.Item>
                    );
                });
                const angle = (i === basePathIndex) ? 'angle up' : 'angle down';
                return {
                    key: `panel-${i}`,
                    title: {
                        content: (<span  onClick={() =>savePartners(menu.partner)} className={(basePath === menu.name.toLowerCase()) ? 'parentActive' : ''}>
        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
            <Icon name={menu.icon}/>}
                            {menu.title}
                            <Icon name={angle}/>
      </span>)
                    },
                    content: {
                        content: (<List  >{childMenus}</List>)
                    }
                };
            }

            // Dont show in menu bar if menu hide is set
            if (menu.hide) {
                return {key: `panel-${i}`};
            }
            return {
                key: `panel-${i}`,
                title: {
                    content: <Link
                        onClick={() =>{
                            savePartners(menu.partner);
                            closeSideBar();
                        }}
                        to={parentLink}
                    >
                        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
                            <Icon name={menu.icon}/>}
                        {menu.title}
                    </Link>
                }
            };

        });
    }

    if (basePath === 'patients') {
        panels = menuptvArray.map((menu: MainMenu, i) => {

            // Check if role is assign for menu
            if (menu.role && menu.role.length) {
                let roleExist = false;
                menu.role.forEach((role: string) => {
                    if (user.role.indexOf(role.toLowerCase()) >= 0) {
                        roleExist = true;
                    }
                });
                if (!roleExist) {
                    return {key: `panel-${i}`};
                }
            }

            const parentLink = `/patients${parseLeftMenuPath(menu.name)}`;
            // Child Menu
            if (menu.subMenus && menu.subMenus.length > 0) {
                const childMenus = menu.subMenus.map((subMenu: SubMenu, j: number) => {

                    // Dont show in menu bar if menu hide is set
                    if (subMenu.hide) {
                        return true;
                    }

                    // Check if role is assign for menu
                    if (subMenu.role && subMenu.role.length) {
                        let roleExist = false;
                        subMenu.role.forEach((role: string) => {
                            if(user.role.indexOf(role.toLowerCase()) >= 0) {
                                roleExist = true;
                            }
                        });
                        if (!roleExist) {
                            return false;
                        }
                    }
                    let childPath = window.location.pathname.split('/')[2];
                    childPath = childPath && childPath.indexOf('-') > -1
                        ? childPath.split('-').join(' ')
                        : childPath;
                    const childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;
                    return (
                        <List.Item
                            key={uuidv4()}
                            className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                        >
                            <Link
                                to={childLink}
                                onClick={closeSideBar}
                                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                            >
                                {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}/> :
                                    <Icon name={subMenu.icon}/>}
                                {subMenu.title}
                            </Link>
                        </List.Item>
                    );
                });
                return {
                    key: `panel-${i}`,
                    title: {
                        content: (<span  onClick={() =>savePartners(menu.partner)} className={(basePath === menu.name.toLowerCase()) ? 'parentActive' : ''}>
        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
            <Icon name={menu.icon}/>}
                            {menu.title}
                            <Icon name={(i === basePathIndex) ? 'angle up' : 'angle down'}/>
      </span>)
                    },
                    content: {
                        content: (<List  >{childMenus}</List>)
                    }
                };
            }

            // Dont show in menu bar if menu hide is set
            if (menu.hide) {
                return {key: `panel-${i}`};
            }
            return {
                key: `panel-${i}`,
                title: {
                    content: <Link
                        onClick={() =>{
                            savePartners(menu.partner);
                            closeSideBar();
                        }}
                        to={parentLink}
                    >
                        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
                            <Icon name={menu.icon}/>}
                        {menu.title}
                    </Link>
                }
            };

        });
    }

    if (basePath === 'admin' || basePath === 'manage help') {
        panels = menuAdminArray.map((menu: MainMenu, i) => {

            // Check if role is assigned for menu
            if (menu.role && menu.role.length) {
                let roleExist = false;
                menu.role.forEach((role: string) => {
                    if (user.role.indexOf(role.toLowerCase()) >= 0) {
                        roleExist = true;
                    }
                });
                if (!roleExist) {
                    return { key: `panel-${i}` };
                }
            }

            const parentLink = menu.parentLink ? menu.parentLink : `/admin${parseLeftMenuPath(menu.name)}`;

            let childMenus = [];
            let childPathIndex
            if (menu.subMenus && menu.subMenus.length > 0) {
                childMenus = menu.subMenus.map((subMenu: SubMenu, j: number) => {
                    // Check if role is assigned for submenu
                    if (subMenu.role && subMenu.role.length) {
                        let roleExist = false;
                        subMenu.role.forEach((role: string) => {
                            if (user.role.indexOf(role.toLowerCase()) >= 0) {
                                roleExist = true;
                            }
                        });
                        if (!roleExist) {
                            return null;
                        }
                    }

                    // Grandchild Menu
                    let grandchildMenus:any = [];
                    if (subMenu.grandSubMenu && subMenu.grandSubMenu.length > 0) {
                        grandchildMenus = subMenu.grandSubMenu.map((grandSubMenu: GrandSubMenu, k: number) => {
                            // Check if role is assigned for grandchild menu
                            if (grandSubMenu.role && grandSubMenu.role.length) {
                                let roleExist = false;
                                grandSubMenu.role.forEach((role: string) => {
                                    if (user.role.indexOf(role.toLowerCase()) >= 0) {
                                        roleExist = true;
                                    }
                                });
                                if (roleExist === false) {
                                    return null;
                                }
                            }

                            let grandchildPath = window.location.pathname.split('/')[3];
                            grandchildPath = grandchildPath && grandchildPath.indexOf('-') > -1
                                ? grandchildPath.split('-').join(' ')
                                : grandchildPath;
                            const grandchildLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}${parseLeftMenuPath(grandSubMenu.name)}`;

                            return (
                                <List.Item
                                    key={uuidv4()}
                                    className={(grandchildPath === grandSubMenu.name.toLowerCase()) ? 'active' : ''}
                                >
                                    <Link
                                        to={grandchildLink}
                                        onClick={closeSideBar}
                                        className={(grandchildPath === grandSubMenu.name.toLowerCase()) ? 'active' : ''}
                                    >
                                        {grandSubMenu.customIcon ? <i aria-hidden="true" className={`${grandSubMenu.customIcon} icon`} /> :
                                            <Icon name={grandSubMenu.icon} />}
                                        {grandSubMenu.title}
                                    </Link>
                                </List.Item>
                            );
                        });

                        // Filter out null values from grandchildMenus
                        grandchildMenus = grandchildMenus.filter(Boolean);

                        if (grandchildMenus.length === 0) {
                            return null;
                        }
                    }

                    let childPath = window.location.pathname.split('/')[2];
                    childPath = childPath && childPath.indexOf('-') > -1
                        ? childPath.split('-').join(' ')
                        : childPath;

                    let hasRedirect = false;
                    let childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;

                    if(subMenu.redirectUrl) {
                        childLink = subMenu.redirectUrl;
                        hasRedirect = true;
                    }

                    return (
                        <List.Item
                            key={uuidv4()}
                            className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                        >
                            {!hasRedirect && <Link
                                to={childLink}
                                onClick={closeSideBar}
                                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                            >
                  <span  onClick={() =>savePartners(menu.partner)} className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}>
                  {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon} icon`} /> :
                      <Icon name={subMenu.icon} />}
                      {subMenu.title}
                      {/* <Icon name={angle} />*/}
                  </span>
                                {grandchildMenus.length > 0 && <List >{grandchildMenus}</List>}
                            </Link>}


                            {hasRedirect && <a
                                href={childLink}
                                target='_blank'
                                onClick={closeSideBar}
                                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                            >
                  <span  onClick={() =>savePartners(menu.partner)} className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}>
                  {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon} icon`} /> :
                      <Icon name={subMenu.icon} />}
                      {subMenu.title}
                      {/* <Icon name={angle} />*/}
                  </span>
                                {grandchildMenus.length > 0 && <List >{grandchildMenus}</List>}
                            </a>}
                        </List.Item>
                    );
                });

                // Filter out null values from childMenus
                childMenus = childMenus.filter(Boolean);

                if (childMenus.length === 0) {
                    return null;
                }

                const angle = (i === basePathIndex) ? 'angle up' : 'angle down';
                return {
                    key: `panel-${i}`,
                    title: {
                        content: (<span onClick={() => savePartners(menu.partner)} className={(basePath === menu.name.toLowerCase()) ? 'parentActive' : ''}>
            {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`} /> :
                <Icon name={menu.icon} />}
                            {menu.title}
                            <Icon name={angle} />
          </span>)
                    },
                    content: {
                        content: (<List>{childMenus}</List>)
                    }
                };
            }

            // Dont show in menu bar if menu hide is set
            if (menu.hide) {
                return null;
            }

            return {
                key: `panel-${i}`,
                title: {
                    content: <Link
                        onClick={() => {
                            savePartners(menu.partner);
                            closeSideBar();
                        }}
                        to={parentLink}
                    >
                        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`} /> :
                            <Icon name={menu.icon} />}
                        {menu.title}
                    </Link>
                }
            };
        });

        // Filter out null values from panels
        panels = panels.filter(Boolean);
    }



    if (basePath === 'spot') {
        panels = menuSPOTArray.map((menu: MainMenu, i) => {

            // Check if role is assign for menu
            if (menu.role && menu.role.length) {
                let roleExist = false;
                menu.role.forEach((role: string) => {
                    if (user.role.indexOf(role.toLowerCase()) >= 0) {
                        roleExist = true;
                    }
                });
                if (roleExist === false) {
                    return {key: `panel-${i}`};
                }
            }

            const parentLink = `/spot${parseLeftMenuPath(menu.name)}`;
            // Child Menu
            if (menu.subMenus && menu.subMenus.length > 0) {
                const childMenus = menu.subMenus.map((subMenu: SubMenu, j: number) => {

                    // Dont show in menu bar if menu hide is set
                    if (subMenu.hide) {
                        return true;
                    }

                    // Check if role is assign for menu
                    if (subMenu.role && subMenu.role.length) {
                        let roleExist = false;
                        subMenu.role.forEach((role: string) => {
                            if(user.role.indexOf(role.toLowerCase()) >= 0) {
                                roleExist = true;
                            }
                        });
                        if (!roleExist) {
                            return false;
                        }
                    }
                    let childPath = window.location.pathname.split('/')[2];
                    childPath = childPath && childPath.indexOf('-') > -1
                        ? childPath.split('-').join(' ')
                        : childPath;
                    const childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;
                    return (
                        <List.Item
                            key={uuidv4()}
                            className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                        >
                            <Link
                                to={childLink}
                                onClick={closeSideBar}
                                className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}
                            >
                                {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}/> :
                                    <Icon name={subMenu.icon}/>}
                                {subMenu.title}
                            </Link>
                        </List.Item>
                    );
                });
                return {
                    key: `panel-${i}`,
                    title: {
                        content: (<span  onClick={() =>savePartners(menu.partner)} className={(basePath === menu.name.toLowerCase()) ? 'parentActive' : ''}>
        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
            <Icon name={menu.icon}/>}
                            {menu.title}
                            <Icon name={i === basePathIndex ? 'angle up' : 'angle down'}/>
      </span>)
                    },
                    content: {
                        content: (<List  >{childMenus}</List>)
                    }
                };
            }

            // Dont show in menu bar if menu hide is set
            if (menu.hide) {
                return {key: `panel-${i}`};
            }
            return {
                key: `panel-${i}`,
                title: {
                    content: <Link
                        onClick={() =>{
                            savePartners(menu.partner);
                            closeSideBar();
                        }}
                        to={parentLink}
                    >
                        {menu.customIcon ? <i aria-hidden="true" className={`${menu.customIcon}  icon`}/> :
                            <Icon name={menu.icon}/>}
                        {menu.title}
                    </Link>
                }
            };

        });
    }
    return (

        <Sidebar
            as={Menu}
            animation='push'
            width='thin'
            visible={visible}
            icon='labeled'
            vertical={true}
            inverted={true}
        >
            <div className="logoHolder">
                <Link to='/partners'>
                    <img src={Logo} className="logo align-self-center" alt="Logo" />
                </Link>
            </div>
            <Accordion className="leftMenu" panels={panels} defaultActiveIndex={basePathIndex} />
        </Sidebar>
    )
}
