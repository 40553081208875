import moment from 'moment'
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'
const _ = require('underscore');

interface props {
    records: any
}
const LambdaTable = (props: props) => {
    const { records } = props;

    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell>Updated At</TableHeaderCell>
                        <TableHeaderCell>Created At</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                      records && _.sortBy(records , (item: any) => [item.success, item.app]).map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs?.app?.split(":")[1].trim()}</TableCell>
                                <TableCell>{logs?.description}</TableCell>
                                <TableCell>{moment(logs?.updatedAt).format('lll')}</TableCell>
                                <TableCell>{moment(logs?.createdAt).format('lll')}</TableCell>
                                <TableCell width={3}>{logs.success ?
                                    <Label style={{width:'100%'}} size='massive' color='green' horizontal></Label> :
                                    <Label style={{width:'100%'}} size='massive' color='red' horizontal></Label>}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default LambdaTable