import React, {useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";
import {Card, Dropdown, Input, Table} from "semantic-ui-react";
import moment from "moment";

interface Props {
    patientId: string | undefined,
    practice: string | undefined,
    result: any[],
    resultOptions: any[]
}

function PatientInformation(props: Props) {

    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({details: null as any, result: null as any, notes: null as any});

    const saveResults = async (obj: any) => {
        setStates({...state, result: obj.result});
        obj.practice=props.practice;
        await spotAction.saveBrokenResult(obj);
    }

    const details = props.result && props.result.filter(f => f.PT_Num == props.patientId);
    return (
        <Card>
            <Card.Content>
                <Card.Header><h3>PATIENT INFORMATION</h3></Card.Header>
            </Card.Content>
            <Card.Content>
                {details && details.map((item: any, key: number) =>
                    <Table key={key + 'test_'} striped={true} style={{border: 'none'}}>
                        <Table.Body key={item}>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Result</b></small>
                                    <br/>
                                    <Dropdown
                                        options={props.resultOptions}
                                        onChange={(e, d) => saveResults({...item, result: d.value})}
                                        value={state.result || item.result}
                                        selection={true}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Notes</b></small>
                                    <br/>
                                    <Input
                                        name='notes'
                                        type='text'
                                        value={state.notes || item.notes}
                                        onChange={(e, d) => setStates({...state, notes: d.value})}
                                        onBlur={(e: any) => saveResults({...item, notes: e.target.value})}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Patient Name</b></small>
                                    <p className="color-secondary">
                                        <b>{item.PT_Name}</b>
                                    </p></Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Patient ID</b></small>
                                    <p className="color-secondary">
                                        <b>{item.PT_Num}</b>
                                    </p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>AGE</b></small>
                                    <p className="color-secondary">
                                        <b>{item.age}</b>
                                    </p></Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Birth Date</b></small>
                                    <p className="color-secondary">
                                        <b>{item.BirthDate}</b>
                                    </p>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Practice</b></small>
                                    <p className="color-secondary text-uppercase">
                                        <b> {props.practice}</b>
                                    </p>
                                </Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Visit Count</b></small>
                                    <p className="color-secondary">
                                        <b>{item.visitCount && item.visitCount.Visit_Count || ''}</b>
                                    </p>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Last Visit</b></small>
                                    <p className="color-secondary">
                                        <b>{item.LastVisit || ''}</b>
                                    </p>
                                </Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Next Appointment</b></small>
                                    <p className="color-secondary">
                                        <b>{(item.Next_Apt) ? item.Next_Apt : ''}</b>
                                    </p>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Show Rate (%)</b></small>
                                    <p className="color-secondary">
                                        <b>{item.showRate && item.showRate.show_rate || ''}</b>
                                    </p>
                                </Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Insurance</b></small>
                                    <p className="color-secondary">
                                        <b>
                                            {item.insurance && item.insurance.Insurance || ''}
                                        </b>
                                    </p>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Best Contact Time</b></small>
                                    <p className="color-secondary">
                                        <b>{item.bestContactTime}</b>
                                    </p></Table.Cell>
                                <Table.Cell>
                                    <small className="primaryColor text-uppercase"> <b>Ins Verified</b></small>
                                    <p className="color-secondary">
                                        <b>
                                            {item.DateLastVerified ? item.DateLastVerified : item.insurance && item.insurance.DateLastVerified ? moment(item.insurance.DateLastVerified).format("MM/DD/YYYY") : ''}
                                        </b>
                                    </p>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan={2}>
                                    <small className="primaryColor text-uppercase"> <b>Phone Number</b></small>
                                    <p className="color-secondary">
                                        <b>
                                            {item.HmPhone || item.WkPhone || item.WirelessPhone || item.phone}
                                        </b>
                                    </p>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )}
            </Card.Content>
        </Card>
    )
}

function mapStateToProps(state: RootState) {
    return {user: state.auth.user}
}

export default connect(mapStateToProps)(PatientInformation)
