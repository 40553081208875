import React, { useEffect, useState } from 'react';
import { useActions } from '../../../redux/actions';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Button, ButtonGroup } from 'semantic-ui-react'
import ProgressBar from '../ProgressBar';
import LambdaTable from './LambdaTable';


function AwsLambdaHealth() {
    const initialState = { panel: '', loading: false, data: [], response: [], counts: { failed: 0, success: 0, pending: 0 } };
    const [state, setState] = useState(initialState);
    const healthStatusActions = useActions(HealthStatusActions);

    const prefix = 'lambdas';
    const { panel, loading, response ,counts } = state;

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }


    useEffect(() => {
        setState({ ...state, loading: true })
        healthStatusActions.get('health-status/aws-lambdas').then((response: any) => {
            const failed = response.filter((o: any) => !o.success).length;
            const counts = { failed: failed, pending: 0, success: response.length - failed };
            healthStatusActions.addToCount(counts).then();
            setState({ ...state, data: response, response, counts, loading: false });
        })
    }, [])

    const view = (passed: boolean) => {
        if (passed)
            setState({ ...state, data: response.filter((o: any) => o.success) });
        else
            setState({ ...state, data: response.filter((o: any) => !o.success) });
    }
    return (
        <>
            <AccordionTitle active={panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>AWS Lambdas</span>
                            {state.loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <ButtonGroup >
                                <Button onClick={() => view(true)} icon='folder outline' color='green' content={`Passed ${(counts.success)}`} />
                                <Button onClick={() => view(false)} labelPosition='right' icon='folder outline' color='red' content={`Failed ${(counts.failed)}`} />
                            </ButtonGroup>
                            {state.data && <LambdaTable records={state.data} />}
                        </GridColumn>
                    </GridRow>
                </Grid>}

            </AccordionContent>
        </>
    );
}

export default AwsLambdaHealth;