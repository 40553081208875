import { User } from "../../../model/user";
import { connect } from "react-redux";
import Template from "../../../components/Template";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { HostedSite, PracticeData } from "./interfaces";
import { useActions } from "../../../redux/actions";
import * as awsHostedSitesActions from "../../../redux/actions/awsHostedSites";
import { Bar } from "react-chartjs-2";
import PerformanceChart from "./components/PerfomanceChart";
import EditSiteModal from './modals/EditSiteModal';
import * as sitesActions from "../../../redux/actions/awsHostedSites";
import * as practiceActions from "../../../redux/actions/practice";
import { SitesDetails } from "../../../model/awsHostedSites";
import { Message } from "semantic-ui-react";

interface Props {
    auth: User
}

const initialState: {
    site: HostedSite | null | undefined,
} = {
    site: undefined,
}

function ViewSingleSite(props: Props) {
    const history = useHistory();
    const sitesAction = useActions(sitesActions)
    const awsHostedSitesAction = useActions(awsHostedSitesActions)
    const params: { siteId?: string } = useParams();
    const [state, setState] = useState(initialState);
    const [practices, setPractices] = useState([])
    const [showEditPopupModal, setShowEditPopupModal] = useState(false);
    const [selectedSiteData, setSelectedSiteData] = useState({})
    const [notification, setNotification] = useState<{ type: string; message: string } | null>(null);

    const parsedId = parseInt("" + params.siteId);
    const siteId = Number.isNaN(parsedId) ? undefined : parsedId;

    const setPracticesList = (res: any) => {
        let practiceList: any = [{ id: 0, practice: 'CORP', brand: 'CDP' }];
        if (Object.keys(res).length) {
            res.forEach((item: any) => {
                practiceList.push({
                    id: item.id,
                    practice: item.practice,
                    brand: item.brand
                })
            })
            setPractices(practiceList)
        }
    }

    useEffect(() => {
        practiceActions.fetchWebsitesPractices(setPracticesList, '')

        awsHostedSitesAction.getSingleWebsiteDetails(siteId).then((result: any) => {
            setState({
                ...state,
                site: result
            })
        });
    }, []);

    function escapeSequencesToHTML(value: string): string {
        const escapeMap: { [key: string]: string } = {
            "\n": "<br>",
            " ": "&nbsp;",
            "\t": "&nbsp;&nbsp;&nbsp;&nbsp;",
            "&": "&amp;",
            '"': "&quot;",
            ">": "&gt;",
            "<": "&lt;"
        };

        return value.replace(/[\n\t&"'><]/g, (char) => escapeMap[char] || char);
    }

    const showEditSiteModal = (payload: any) => {
        if(payload !== true && (!payload || !payload.id)) return;

        setSelectedSiteData(payload)
        setShowEditPopupModal(!showEditPopupModal);
    }

    const combineObjects = (A: any, B: any) => {
        const combined = { ...A };
        for (const key in B) {
            if (!A.hasOwnProperty(key)) {
                combined[key] = B[key];
            }
        }
        return combined;
    }

    const onSubmit = async (values: SitesDetails) => {
        setShowEditPopupModal(false);
        try {
            await sitesAction.saveSite(values);
            const site = combineObjects(values, state.site);
            setState({
                ...state,
                site
            });
            setNotification({ type: "success", message: "Site updated successfully!" });
        } catch (error) {
            setNotification({ type: "error", message: "Failed to update site. Please try again." });
        }
    }

    const normalizeUrl = (inputUrl: string) => {
        const protocolRegex = /^(https?:\/\/)/i;
        if (protocolRegex.test(inputUrl)) {
            return inputUrl;
        } else {
            return 'http://' + inputUrl;
        }
    }

    const getPracticesLabels = (practiceList: string) => {
        const practices = practiceList.split(',');
        return (<ul className="website-practices">
            {practices.map((practice) => <li>
                {practice.trim()}
            </li>)}
        </ul>)
    }

    return (<Template activeLink='aws websites'>
        {notification && (
            <Message
                style={{
                    position: "fixed",
                    top: "10px",
                    left: "10px",
                    zIndex: 9999,
                    maxWidth: "300px",
                    fontSize: "12px",
                    padding: "10px",
                }}
                color={notification.type === "error" ? "red" : "green"}
                icon={notification.type === "error" ? "exclamation triangle" : "check circle"}
                header={notification.type === "error" ? "Error" : "Success"}
                content={notification.message}
                onDismiss={() => setNotification(null)}
            />
        )}
        {showEditPopupModal && <EditSiteModal data={selectedSiteData} onSubmitHandler={onSubmit} showEditSiteModalHandler={showEditSiteModal} />}
        {siteId && <main className='aws-websites-root aws-websites-single poppins-regular'>
            <div className="websites-data-tables-section">
                <button 
                    id="backButton"
                    onClick={() => history.goBack()} 
                    className="back-button mb-4 flex items-center text-blue-600 hover:text-blue-800"
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        padding: '8px',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5} 
                        stroke="currentColor" 
                        style={{ width: '20px', height: '20px' }}
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" 
                        />
                    </svg>
                    Back to previous page
                </button>

                <h1 className="inline-block">
                    <a title="Open the live website" target='_blank' href={normalizeUrl(`${state.site?.liveSite}`)}>Viewing {state.site?.liveSite}</a>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                        stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                    </svg>
                </h1>

                <a title={`This website is hosted ${state.site?.contentManager === 'CDP' ? 'by CDP' : 'externally'} `} style={{ transform: "translateY(15px)", cursor: 'none', background: '#000' }} className="highlighted-label float-right" href="javascript:void(0)">{`${state.site?.contentManager === 'CDP' ? 'CDP' : 'External'}`} Hosted Site</a>
                <a title="" onClick={() => showEditSiteModal(state.site)} style={{ transform: "translateY(15px)", cursor: 'pointer', marginRight: '10px' }} className="highlighted-label float-right" href="javascript:void(0)">Edit Site</a>

                {state.site?.type === 'Wordpress' && <div className="inline-block float-right mr-xs" title="This is a WordPress site">
                    
                </div>}

                {getPracticesLabels(`${state.site?.practice}`)}

                <div className="stat-grid">
                    <div className="stat-card">
                        <h4>Roadmap</h4>
                        <p dangerouslySetInnerHTML={{ __html: escapeSequencesToHTML(`${state.site?.roadMap}`) }}></p>
                    </div>
                </div>
            </div>

            <div className="website-stats-grid mt-small">
                <div className="websites-data-tables-section in-grid">
                    <h2>
                        Uptime over last week
                    </h2>

                    <Bar options={{
                        scales: {
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    max: 100
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem: any, data: any) {
                                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += tooltipItem.yLabel + '%';
                                    return label;
                                }
                            }
                        }
                    }} data={{
                        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                        datasets: [{
                            label: 'Uptime',
                            data: [100, 100, 75.4, 97.4, 99, 100, 100],
                            borderWidth: 1,
                        }]
                    }} />
                </div>

                <div className="websites-data-tables-section in-grid">
                    <h2>
                        Performance Metrics
                    </h2>

                    <div className="stat-grid">
                        <div className="stat-card">
                            <h4>Date Analyzed</h4>
                            <h2>Apr 25, 2024</h2>
                        </div>

                        <div className="stat-card">
                            <h4>Performance Score</h4>
                            <PerformanceChart performanceValue={74} />
                        </div>

                        <div className="stat-card">
                            <h4>Load Time</h4>
                            <PerformanceChart performanceValue={90} unit="s" />
                        </div>

                        <div className="stat-card">
                            <h4>SEO Score</h4>
                            <PerformanceChart performanceValue={70} unit="." />
                        </div>
                    </div>

                    <div className="stat-grid mt-md pb-sm">
                        <div className="stat-card">
                            <h4>Accessibility Score</h4>
                            <PerformanceChart performanceValue={74} unit="." />
                        </div>
                    </div>
                </div>
            </div>
        </main>}

        {!siteId && <main className='aws-websites-root failure aws-websites-single'>
            <h1>A catastrophic error has occurred while trying to render this page. Please return to the previous
                page.</h1>

            <a className={'has-icon'} href="/aws/websites">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>

                Back to websites dashboard
            </a>
        </main>}
    </Template>)
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(ViewSingleSite);