// Add {hide: true} in object to hide any menu from menu bar
import {MainMenu} from "./constants";

export const menuSPOTArray: Array<MainMenu> = [
    {
        pageId: 1,
        name: "Support Patient Opportunity Tool",
        class: "innerDropdown",
        icon: "users",
        title: "SPOT",
        partner: "SPOT",
        role: [],
        subMenus: [],
    },
    {
        pageId: 2,
        name: "Usage Spot",
        class: "innerDropdown",
        icon: "pie chart",
        title: "Usage",
        partner: "Usage Spot",
        role: [],
        subMenus: [],
    },
    {
        pageId: 3,
        name: "90 days Unscheduled Treatment",
        class: "innerDropdown",
        icon: "calendar",
        title: "90 days Unscheduled Treatment",
        partner: "Usage Spot",
        role: [],
        subMenus: [],
    },
    {
        pageId: 4,
        name: "SPOT Patients",
        class: "innerDropdown",
        icon: "user md",
        title: "SPOT Patients",
        partner: "Usage Spot",
        role: [],
        subMenus: [],
    },
    {
        pageId: 5,
        name: "SPOT Patient Retention",
        class: "innerDropdown",
        icon: "recycle",
        title: "SPOT Patient Retention",
        partner: "Usage Spot",
        role: [],
        subMenus: [],
    },
    {
        pageId: 5,
        name: "SPOT Work",
        class: "innerDropdown",
        icon: "chart line",
        title: "SPOT Work",
        partner: "SPOT",
        role: [],
        subMenus: [],
    }
]